import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = environment.ExchangeInfoUrl || '';

@Injectable()
export class ExchangeInfoService {
  constructor(private http: HttpClient) {}

  public async getSwapCount(): Promise<any> {
    try {
      const url = `${baseUrl}/swap-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  public async getCrossChainCount(): Promise<any> {
    try {
      const url = `${baseUrl}/cross-chain-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  public async getOpportunitiesCount(): Promise<any> {
    try {
      const url = `${baseUrl}/opportunity-staking-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  public async getCrossChainData() {
    try {
      const baseURL = environment.CCOPERATOR_BASEURL;
      const currentTime = Date.now();
      let fromTime = 1728432000000;
      let toTime = 1729123200000;
      const durationTime = 691200000;

      while (toTime < currentTime) {
        fromTime = toTime;
        toTime = toTime + durationTime;
      }

      const params = {
        fromTime: fromTime,
        toTime: toTime
      };
      const url = `${baseURL}/scanner/getDailyRevenue/data`;
      const result = <any>(
        await this.http.get(url, { params: params }).toPromise()
      );
      return {
        result: result,
        fromTime: ExchangeInfoService.getTimeToDisplay(fromTime),
        toTime: ExchangeInfoService.getTimeToDisplay(toTime - 1000)
      };
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  private static getTimeToDisplay(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate(); // Gets the day of the month in UTC
    const month = date.toLocaleString('default', {
      month: 'long',
      timeZone: 'UTC'
    });

    return `${month} ${day}`;
  }
}
